import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, InputLabel,MenuItem,Select,FormHelperText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Draggable from 'react-draggable';
import {  Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Cookies from 'js-cookie';

function ProductForm({ onSubmit }) {
  const [userRole, setUserRole] = useState('');
  const [inventory, setInventory] = useState([]);
  const fileInputRef = useRef(null);
  const [fields, setFields] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [fieldNullable, setFieldNullable] = useState('');
  const fieldExampleMapping = {
    'character varying': 'Ex: text/12ab',
    text: 'Ex:text',
    date: 'YYYY-MM-DD',
    'timestamp without time zone': 'YYYY-MM-DD',
    integer: '123',
    bigint: '1234567890',
    numeric: '123.45',
    real: '123.45',
    'double precision': '123.45'
  };

  const apiurl = process.env.React_App_API_URL;
const fetchUserRole = () => {
    const role = localStorage.getItem('typ');
    setUserRole(role);
    // Get all items from localStorage
    const items = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      items.push({ key, value });
    }
  };
  const capitalizeWords = (string) => {
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const userID = Cookies.get('userID'); // Retrieve the userID from the cookie

  
  useEffect(() => {
    const fetchInitialFields = async () => {
      try {
        // Fetch column names and data types from the backend API
        const response = await fetch(`${apiurl}/api/getColumns/Inventory`); // Replace '/api/getColumns' with your actual backend endpoint
        const data = await response.json();
        // Process the data and initialize the state dynamically
        if (data && Array.isArray(data.columns)) {
          // Filter out "creat_tmst" and "inventory_id" fields
          const filteredColumns = data.columns.filter(column => column.name !== 'creat_tmst' && column.name !== 'inventory_id' && column.name !== 'updated_at' && column.name !== 'updated_by');
          const capitalizedColumns = filteredColumns.map(column => ({
            name: capitalizeWords(column.name),
            type: column.type,
            nullable: column.nullable
          }));
  
          setFields(filteredColumns);
        }
       await  fetchUserRole();
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };
  
    fetchInitialFields(); // Call the function inside useEffect
  
  }, [apiurl]); // Add an empty dependency array to run the effect only once
 
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addField = async () => {
    try {
      if (!fieldName || !fieldType) {
        console.error('Missing required field parameters');
        // Display an error message to the user
        // You can add code here to show an error message to the user
        return;
      }
  
      // Send a POST request to the API endpoint to add the field
      const response = await axios.post(`${apiurl}/api/addField/"Inventory"`, { 
        name: fieldName, 
        type: fieldType, 
        nullable: fieldNullable 
      });
  
      // Check if the field was added successfully
      if (response.status === 200) {
        // If successful, update the local state and close the dialog
        setFields(prevFields => [
          ...prevFields, 
          { name: fieldName, type: fieldType, nullable: fieldNullable  }
        ]);        
        setFieldName('');
        setFieldType('');
        setFieldNullable(false);
        handleCloseDialog();
      } else {
        // Handle error response from the server
        console.error('Error adding field:', response.data.error);
        // Display an error message to the user
        // You can add code here to show an error message to the user
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error adding field:', error);
      // Display an error message to the user
      // You can add code here to show an error message to the user
    }
  };
  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please select a file to upload");
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', file);
      const updated_by = Cookies.get('userID'); // Get userID from cookies
      const updated_at = new Date().toISOString(); // Get current timestamp

      formData.append('updated_by', updated_by);
      formData.append('updated_at', updated_at);

      const response = await axios.post(`${apiurl}/api/uploadinventory`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded successfully:', response.data);
      alert('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };
  
  const fetchInventory = async () => {
    try {
      const response = await axios.get(`${apiurl}/api/inventory`);
      setInventory(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  const handleImportInventory = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }

  };
  
  // Event handler for file input change
  const handleExportInventory = async () => {
    try {
      // Fetch columns from the backend
      const columns_response = await fetch(`${apiurl}/api/getColumns/Inventory`);
      const columns_data = await columns_response.json();
      const columns = columns_data.columns;
      const inventoryData = await fetchInventory();

      // Check if inventory data is defined
      if (!inventoryData || inventoryData.length === 0) {
        console.error('Inventory data is undefined or empty');
        return;
      }

        // Generate CSV file name with timestamp
      const timestamp = new Date().toISOString().replace(/:/g, '-').replace(/\..+/, '');
      const fileName = `Inventory_${timestamp}.csv`;
  
      // Convert inventory data to CSV using columns
      const inventoryCSV = convertInventoryToCSV(inventoryData, columns);
  
      // Create Blob object and download the CSV file
      const blob = new Blob([inventoryCSV], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error exporting inventory:', error);
      // Handle error
    }
  };
   
  const convertInventoryToCSV = (data, columns) => {
    // Generate CSV header dynamically based on columns
    let csvHeader = columns.map(column => column.name).join(',');
    let csv = `${csvHeader}\n`;
  
    // Generate CSV data rows
    data.forEach(item => {
      let rowData = columns.map(column => item[column.name]).join(',');
      csv += `${rowData}\n`;
    });
  
    return csv;
  };
    const initialState = {};
  const [productData, setProductData] = useState(initialState);

   const handleChange = (e) => {
    const updated_by = Cookies.get('userID'); // Get userID from cookies
    const updated_at = new Date().toLocaleDateString(); // Get current timestamp
    const { name, value } = e.target;

    const newProductData = {
      ...productData,
      updated_by,
      updated_at,
      [name]: value,
    };

    console.log('Changing:', name, value);
    setProductData(newProductData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // No need to filter out empty fields
    try {
      console.log('Submitting form:', productData);
      const response = await axios.post(`${apiurl}/api/inventory/`, productData);
      console.log('Response:', response.data);
      setProductData(initialState);
      alert('Record added successfully!'); // Show success message
      window.location.reload(); // Auto-refresh the page
    } catch (error) {
      alert('Error while inserting the record:', error);
    }
  };
  

    const handleTypeChange = (event) => {
    setFieldType(event.target.value);
  };
  const handleNullableChange= (event) => {
    setFieldNullable(event.target.value);
  };
  const handleMouseDown = (event) => {
    // Prevent default behavior to avoid text selection when dragging
    event.preventDefault();

    // Implement dragging functionality here
  };
  return (
    <form onSubmit={handleSubmit} className="product-form">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ color: '#18B7BE', fontSize: '24px', marginBottom: '10px' }}>Add Product</h3>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '30px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#178CA4', marginRight: '20px' }}
            onClick={handleImportInventory}
          >
            Import Inventory
          </Button>
          <input
            type="file"
            accept=".csv"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#178CA4', marginLeft: '10px' }}
            onClick={handleExportInventory}
          >
            Export Inventory
          </Button>
        </div>
      </div>
      <Grid container spacing={2}>
      {fields.map((field, index) => (
        <Grid item xs={3} sm={8} md={3} lg={1.5} key={index}>
          <InputLabel htmlFor={field.name}>
            {`${field.name.replace(/_/g, ' ')}`}
          </InputLabel>
          {field.type === 'date' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id={field.name}
                name={field.name}
                value={productData[field.name]}
                onChange={(newValue) => handleChange({ target: { name: field.name, value: newValue } })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder={fieldExampleMapping[field.type]}
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            <TextField
              id={field.name}
              name={field.name}
              value={productData[field.name]}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder={fieldExampleMapping[field.type]}
            />
          )}
        </Grid>
      ))}
    </Grid>

      <div>
      {userRole === 'A' && (
        <Button variant="contained" style= { {backgroundColor: '#178CA4',marginTop:'2%' }} onClick={handleOpenDialog}>
          Add Field
        </Button>
      )}
        <Dialog open={openDialog} onClose={handleCloseDialog}>       
        <DialogTitle style={{ cursor: 'move' }} onMouseDown={handleMouseDown} >Add New Field</DialogTitle>
<DialogContent>
  <TextField
    autoFocus
    margin="dense"
    id="name"
    label="Field Name"
    type="text"
    fullWidth
    value={fieldName}
    onChange={(e) => setFieldName(e.target.value)}
  />
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <InputLabel>Data Type</InputLabel>
      <Select
        labelId="datatype-label"
        id="datatype"
        value={fieldType}
        label="Data Type"
        onChange={handleTypeChange}
        fullWidth>
        <MenuItem value="date">Date</MenuItem>
        <MenuItem value="integer">Number</MenuItem>
        <MenuItem value="varchar(100)">Character</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={12} sm={6}>
      <InputLabel>Is Mandatory Field</InputLabel>
      <Select
        labelId="nullable-label"
        id="nullable"
        value={fieldNullable}
        label="Mandatory"
        onChange={handleNullableChange}
        fullWidth>
         <MenuItem value="NOT NULL">Yes</MenuItem>
    <MenuItem value="NULL">No</MenuItem>
      </Select>
    </Grid>
  </Grid>
</DialogContent>
<DialogActions>
  <Button onClick={handleCloseDialog}>Cancel</Button>
  <Button onClick={addField}>Add</Button>
</DialogActions>
        </Dialog>
      </div>
      <Button type="submit" variant="contained" color="primary" style={{  backgroundColor: '#178CA4', marginTop: '2%',marginLeft:'93%',fontSize: '100%' }} onClick={handleSubmit}>
        Add Record
      </Button>
          </form>
  );
}
export default ProductForm;
