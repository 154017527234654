import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ProductTable from './ProductTable';
import ProductForm from './ProductForm';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { checkInactivity } from '../Homepage/sessionService';
import SessionValidationHOC from '../Homepage/SessionValidationHOC';
import { useNavigate } from 'react-router-dom';

function Products() {
  const [products, setProducts] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // Track selected product for deletion
  const apiurl = process.env.React_App_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkInactivity(navigate); // Pass navigate to the checkInactivity function
    }, 60 * 1000); // Check every minute

    return () => clearInterval(intervalId);
  }, [navigate]);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}/api/inventory`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [apiurl]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleAddProduct = async (productData) => {
    try {
      const response = await axios.post(`${apiurl}/api/inventory`, productData);
      setProducts((prevProducts) => [...prevProducts, response.data]);
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleDeleteConfirmation = (product) => {
    setSelectedProduct(product);
    setShowConfirmation(true);
  };

  const handleDeleteClick = async () => {
    try {
      if (!selectedProduct) return;

      await axios.delete(`${apiurl}/api/inventory/${selectedProduct.inventory_id}`);
           setProducts((prevProducts) =>
        prevProducts.filter((product) => product.inventory_id !== selectedProduct.inventory_id)
      );
      fetchProducts();
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setSelectedProduct(null);
  };

  const handleUpdateProduct = async (inventoryId, updatedProductData) => {
    try {
      await axios.put(`${apiurl}/api/inventory/${inventoryId}`, updatedProductData);
           setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.inventory_id === inventoryId ? { ...product, ...updatedProductData } : product
        )
      );
      fetchProducts();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  

  return (
    <div>
      
      <div style={{ paddingLeft: '20px' }}>
        <ProductForm onSubmit={handleAddProduct} />
        <ProductTable products={products} onDelete={handleDeleteConfirmation} onUpdate={handleUpdateProduct} />
      </div>
      {/* Confirmation dialog */}
      <Dialog open={showConfirmation} onClose={handleCancelDelete}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this product "{selectedProduct?.brand_name}"?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SessionValidationHOC(Products);
