import React from "react";
import OrderManagement from '../Order/order';

import PharmaHeader from "../Homepage/pharmaheader";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Orderp () {
    return(
    
        <div>
          <PharmaHeader />
            <OrderManagement />
                
        </div>
    )
}
export default SessionValidationHOC(Orderp);