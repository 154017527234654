import React from "react";
import Return from '../Order/return'
import PharmaAssistent from "../Homepage/Pharma_assist";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Returnpa () {
    return(
    
        <div>
          <PharmaAssistent />
            <Return />
                
        </div>
    )
}
export default SessionValidationHOC(Returnpa);