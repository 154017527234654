import './index.css';
import HomePage from './Homepage/Homepage';
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pharmaheader/Dashboardp';
import PharmaHeader from './Homepage/pharmaheader';
import Billinga from './Admin/billinga';
import AdminPage from './Admin/Usermanagement';
import Return from './Order/return';
import Dashboarda from './audits/Dashboarda';
import SalesAndBilling from './Sales/sales';
import OverTheCounterSale from './Sales/counterbill';
import Ordera from './Admin/ordera';
import InventoryChart from './audits/Inventory';
import Inventoryp from './pharmaheader/inventoryp';
import Orderp from './pharmaheader/orderp';
import Returnp from './pharmaheader/returnp';
import Billingp from './pharmaheader/billingp';
import Inventorya from './Admin/inventorya';
import PharmaAssistent from './Homepage/Pharma_assist';
import Orderpa from './pharmaheader/orderpa';
import Returnpa from './pharmaheader/returnpa';
import Billingpa from './pharmaheader/billingpa';
import Inventorypa from './pharmaheader/inventorypa';
function VaidhyaPharma() {
  return (
    <Router>
   <div>
      <Routes>
       {/* <Route path="*" element={<Login />} />  */}
        <Route path="/home" element={<HomePage />} /> 
        <Route path="/inventorya" element={<Inventorya />} /> 
        <Route path="/billinga" element={<Billinga />} />
        <Route path="/Dashboardp" element={<Dashboard />} />
        <Route path="/Pharmaheader" element={<PharmaHeader />} />
        <Route path="/usermanag" element={<AdminPage />} />
        <Route path="/chart" element={<InventoryChart />} />
        <Route path="/Dashboarda" element={<Dashboarda/>} />
        <Route path="/sales " element={<SalesAndBilling />} />
        <Route path='/scanbill' element={<OverTheCounterSale />} />
        <Route path='/ordera' element={<Ordera/>} />
        <Route path='/return' element={<Return/>} />
        <Route path='/orderp' element={<Orderp />} />
        <Route path='/inventoryp' element={<Inventoryp />} />
        <Route path="/billingp" element={<Billingp />} />
        <Route path='/returnp' element={<Returnp/>} />
        <Route path='/pharmaassist' element={<PharmaAssistent/>} />
        <Route path='/orderpa' element={<Orderpa />} />
        <Route path='/returnpa' element={<Returnpa/>} />
        <Route path='/billingpa' element={<Billingpa />} />
        <Route path='/inventorypa' element={<Inventorypa />} />
                {/*  <Route path="/Doctorhome" element={<Doctorhome />} /> */}
        {/* Add more routes as needed */}
      </Routes>
      </div>
    </Router>
   
  )
}

ReactDOM.render(<VaidhyaPharma />, document.getElementById("root"));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
///reportWebVitals();
