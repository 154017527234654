import React from "react";
import Billing from '../Sales/bill';

import PharmaHeader from "../Homepage/pharmaheader";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Billingp () {
    return(
    
        <div>
          <PharmaHeader />
            <Billing />
                
        </div>
    )
}
export default SessionValidationHOC(Billingp);