import React from "react";
import OrderManagement from '../Order/order';
import PharmaAssistent from "../Homepage/Pharma_assist";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Orderpa () {
    return(
    
        <div>
          <PharmaAssistent />
            <OrderManagement />
                
        </div>
    )
}
export default SessionValidationHOC(Orderpa);