import React from "react";
import OrderManagement from '../Order/order';

import Adminheader from "./adminheader";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Ordera () {
    return(
    
        <div>
          <Adminheader />
            <OrderManagement />
                
        </div>
    )
}
export default SessionValidationHOC(Ordera);