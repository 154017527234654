import React from "react";
import Billing from '../Sales/bill';

import Adminheader from "./adminheader";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Billinga () {
    return(
    
        <div>
          <Adminheader />
            <Billing />
                
        </div>
    )
}
export default SessionValidationHOC(Billinga);