import React, { useState, useEffect, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material';
import Cookies from 'js-cookie';
function ProductTable({ products, onDelete, onUpdate }) {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedProductData, setUpdatedProductData] = useState({});
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [openFieldSelection, setOpenFieldSelection] = useState(false);
  const [filters, setFilters] = useState({
    updated_at: '',
    updated_by: ''
  });
  
  const apiurl = process.env.React_App_API_URL;

  const fetchInventory = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}/api/inventory`);
      setSearchResults(response.data);
      // Extract fields from the first item in the response
      if (response.data.length > 0) {
        const extractedFields = Object.keys(response.data[0]);
        setFields(extractedFields);
        setSelectedFields(extractedFields); // Initially select all fields
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  }, [apiurl]);

  useEffect(() => {
    // Fetch initial inventory data
    fetchInventory();
  }, [fetchInventory]);

  const handleSearchChange = async (event) => {
    setSearchTerm(event.target.value);
    try {
      const response = await axios.get(`${apiurl}/api/inventory/search`, {
        params: { query: event.target.value }
      });
      setSearchResults(response.data);
      // If no results found, enable adding new drug
      if (response.data.length === 0) {
        setShowUpdateModal(true);
        setSelectedProduct(null);
        setUpdatedProductData({});
      }
    } catch (error) {
      console.error('Error searching products:', error);
    }
  };

  const handleUpdateClick = (product) => {
    setSelectedProduct(product);
    setUpdatedProductData({ ...product });
    setShowUpdateModal(true);
  };

  const handleSaveUpdate = async () => {
    if (!selectedProduct) return;
  
    try {
      await axios.put(`${apiurl}/api/inventory/${selectedProduct.inventory_id}`, updatedProductData);
      setShowUpdateModal(false);
      onUpdate(); // Refresh the product list
      window.location.reload();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  

  const userID = Cookies.get('userID');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedProductData({ ...updatedProductData,
      updated_by: userID ,      
      updated_at : new Date().toISOString(),
      [name]: value, });
  };
 
  // const handleFieldSelectionChange = (event) => {
  //   const selectedField = event.target.value;
  //   setSelectedFields(prevSelectedFields => {
  //     if (prevSelectedFields.includes(selectedField)) {
  //       // If the field is already selected, remove it from the array
  //       return prevSelectedFields.filter(field => field !== selectedField);
  //     } else {
  //       // If the field is not selected, add it to the array
  //       return [...prevSelectedFields, selectedField];
  //     }
  //   });
  // };

  const handleFieldSelectionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFields((prevSelectedFields) => {
      if (checked) {
        return [...prevSelectedFields, value];
      } else {
        return prevSelectedFields.filter((field) => field !== value);
      }
    });
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  
  const handleToggleAllFields = (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedFields(fields);
    } else {
      setSelectedFields([]);
    }
  };

  return (
    <div style={{ marginBottom: '250px' }}>
      <h3 style={{ color: '#18B7BE', fontSize: '24px', marginBottom: '10px' }}>Search Medicine</h3>
      <TextField
        fullWidth
        placeholder="Search by Medicine Name"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
     
    <Button variant="contained" onClick={() => setOpenFieldSelection(true)} style={{backgroundColor: '#178CA4', marginTop: '1%',marginLeft:'93%'}}>Fields List</Button>
<Dialog open={openFieldSelection} onClose={() => setOpenFieldSelection(false)}>
        <DialogTitle>Field Selection</DialogTitle>
        <DialogContent>
          <FormControl fullWidth  component="fieldset">
          <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={selectedFields.length === fields.length} onChange={handleToggleAllFields} />}
                label="Select All"
              />
              {fields.map((field) => (
                <FormControlLabel
                  key={field}
                  control={<Checkbox checked={selectedFields.includes(field)} onChange={handleFieldSelectionChange} value={field} />}
                  label={field}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFieldSelection(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {selectedFields.map(field => (
                <TableCell key={field} sx={{ color: '#18B7BE' }}>{field}</TableCell>
              ))}
              <TableCell sx={{ color: '#18B7BE' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((product) => (
              <TableRow key={product.inventory_id}>
                {selectedFields.map(field => (
                  <TableCell key={field}>{product[field]}</TableCell>
                ))}
                <TableCell>
                <Button variant="contained" color="primary" style={{ backgroundColor: '#55c2da', marginRight: '10px' }} onClick={() => handleUpdateClick(product)}>Update</Button>
                  <Button variant="contained" color="primary" style={{ backgroundColor: '#55c2da' }} onClick={() => onDelete(product)}>Delete</Button>
                                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showUpdateModal} onClose={() => setShowUpdateModal(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Update Product</DialogTitle>
      <DialogContent>
      <Grid container spacing={2}>
  {selectedFields.map(field => (
    (field !== 'updated_at' && field !== 'updated_by') && (
      <Grid item xs={12} key={field}>
        <TextField
          label={field}
          name={field}
          value={updatedProductData[field] || ''}
          onChange={handleInputChange}
          disabled={field === 'inventory_id'}
          fullWidth
          variant="outlined"
          margin="dense"
          helperText={updatedProductData[field] === '' ? 'Field can be empty' : ''}
        />
      </Grid>
    )
  ))}
</Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowUpdateModal(false)} color="secondary">Cancel</Button>
        <Button onClick={handleSaveUpdate} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
export default ProductTable;
