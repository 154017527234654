import React from "react";
import Billing from '../Sales/bill';

import PharmaAssistent from "../Homepage/Pharma_assist";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Billingpa () {
    return(
    
        <div>
          <PharmaAssistent />
            <Billing />
                
        </div>
    )
}
export default SessionValidationHOC(Billingpa);