import React from "react";
import Products from '../Product/product';
import PharmaAssistent from "../Homepage/Pharma_assist";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Inventorypa () {
    return(
    
        <div>
          <PharmaAssistent />
            <Products />
                
        </div>
    )
}
export default SessionValidationHOC(Inventorypa);