import React from "react";
import Products from '../Product/product';

import PharmaHeader from "../Homepage/pharmaheader";
import SessionValidationHOC from '../Homepage/SessionValidationHOC';

function Inventoryp () {
    return(
    
        <div>
          <PharmaHeader />
            <Products />
                
        </div>
    )
}
export default SessionValidationHOC(Inventoryp);