import React, { useState, useEffect,useRef,useCallback} from 'react';
import axios from 'axios'
import {  List, ListItem, TextField, Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Grid, MenuItem,Paper,Typography} from '@mui/material';
import 'jspdf-autotable';
import { Autocomplete } from '@mui/material';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PharmaHeader from '../Homepage/pharmaheader';
import PharmaAssistent from '../Homepage/Pharma_assist';
import Cookies from 'js-cookie';

function Counterbill() {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [prescription, setPrescription] = useState('');
  const [selectedMedicines, setSelectedMedicines] =  useState([  { product_name: '', purchase_price: 0, quantity: 0, stock_quantity: 0, expiration_date: '',location:'' }
]);
  const [inventory, setInventory] = useState([]);
  const [userRole, setUserRole] = useState('');
  // const [inventorySearchTerm, setInventorySearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
   const [PrescriptionId, setPrescriptionId]= useState('');
  const [paymentType, setPaymentType] = useState('');
    const [status, setStatus] = useState('');
  const [transactionId, setTransactionId] = useState('');
  // const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  // const [cashAmountProvided, setCashAmountProvided] = useState(0);
  // const [changeToCustomer, setChangeToCustomer] = useState(0);
  const [showDeleteSuccessMessage,setshowDeleteSuccessMessage] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [message, setMessage] = useState('');
  const [showCashPrompt, setShowCashPrompt] = useState(false);
  const [cashInput, setCashInput] = useState('');
  const getDrugName = (medicine) => {
    return medicine.brand_name || medicine.generic_name || medicine.salts_ingredients || 'Unknown Drug';
  };
   const [showMessage, setShowMessage] = useState(false);
  // const [cashAmount, setCashAmount] = useState(null);
  const apiurl=process.env.React_App_API_URL;
  const tableRef = useRef(null);
  const fetchPatients = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}/api/patients`);
      setPatients(response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
      console.log(`Inside the Billing `);

    }
  }, [apiurl]);

  const fetchInventory = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}/api/inventory`);
      setInventory(response.data);
      // Extracting medicine options from inventory data
      await response.data.map(item => ({
        generic_name: item.generic_name,
        brand_name: item.brand_name,
        salts_ingredients:item.salts_ingredients,
        barcode:item.barcode
      }));
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  }, [apiurl]);
  
const handleDiscountChange = (event) => {
  setDiscount(event.target.value);
};
// const handleSuccessfulCardPayment = () => {
//     // Handle successful card payment
//     setStatus('successful');
//     setTransactionId('123444Sample');
//     handleAddBilling();
//   };

  // const handleFailedCardPayment = () => {
  //   // Handle failed card payment
  //   setStatus('failure');
  //   setTransactionId('');
  //   setOpen(false); // Close the dialog
  // };
 
  const handleClose = () => {
    setOpen(false);
    setTransactionId('');
  };

  const handleSubmit = () => {
    handleAddBilling(); // Call the billing function with the transaction ID
    handleClose(); // Close the dialog after submission
  };

  const handlePaymentTypeSelect = (type) => {
    setPaymentType(type);
    if (type === 'CASH') {
      handleAddBillingCash();
    } else if (type === 'CARD') {
     setOpen(true);
    }
  };
  const handleAddBillingCash = async () => {
    setShowCashPrompt(true);
  };

  const handleCashInputConfirm = async () => {
    const cashAmount = parseFloat(cashInput);
    setShowCashPrompt(false);

    if (!isNaN(cashAmount)) {
      // Calculate total bill amount
      const totalAmount = (selectedMedicines.reduce((total, medicine) => total + ((medicine.selling_price/medicine.units_per_package) * medicine.quantity), 0) * (1 - (discount / 100))).toFixed(2);

      // Calculate change
      const change = cashAmount - totalAmount;
      const changeMessage = `Change to Customer: ${change.toFixed(0)}`;

      // Set message and show it
      setMessage(`Total: ${totalAmount}\n${changeMessage}`);
      setShowMessage(true);

      // Update state with total amount and change
      // setCashAmount(cashAmount);
      setTotalAmount(totalAmount);
      // setChangeToCustomer(change);

      // Call handleAddBilling function with provided cash amount (replace with your actual function)
      await setStatus('successful');
      await setTransactionId('CASH');
      await handleAddBilling();

    } else {
      setMessage('Invalid cash amount. Please enter a valid number.');
      setShowMessage(true);
    }
  };
 
  useEffect(() => {
    const fetchUserRole = () => {
      const role = localStorage.getItem('typ');
      setUserRole(role);
      // Get all items from localStorage
      const items = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        items.push({ key, value });
      }
    };
    
    fetchInventory();
    fetchUserRole();
  }, [fetchInventory]);

const generatePrescriptionId = () => {
  const moment = require('moment');

  const date = moment().format('YYYYMMDDHHmmss');
  return `VP43${date}`;
};
useEffect(() => {
  setPrescriptionId(generatePrescriptionId());
}, []);

const handleAddBilling = async () => {
   try {
    const userId = Cookies.get('userID'); // Get the userID from the cookie
    const Prescriptionids = PrescriptionId;
    const drugNames = selectedMedicines.map(medicine => getDrugName(medicine));
    const drugPrices = selectedMedicines.map(medicine => (medicine.selling_price/medicine.units_per_package));
    const drugQuantities = selectedMedicines.map(medicine => medicine.quantity);
    const totalAmount = selectedMedicines.reduce((total, medicine) => total + ((medicine.selling_price/medicine.units_per_package) * medicine.quantity), 0);
    const tdAmount = (totalAmount * discount / 100).toFixed(2);
    const netBill = (totalAmount * (1 - (discount / 100))).toFixed(2);
      await axios.post(`${apiurl}/api/pharmacy_billing`, {
      Patient_id: selectedPatient ? selectedPatient.Patient_Id : null,
      Prescription_id: Prescriptionids,
      Prescription: prescription,
      Drug_name: drugNames,
      per_unit_price: drugPrices,
      Date_sale: new Date().toISOString(),
      Drug_quantity: drugQuantities,
      Total_amount: totalAmount.toFixed(2),
      Discount_amount: tdAmount, // Include the discount amount
      Net_bill: netBill, // Include the net bill
      creat_tmst: new Date().toISOString(),
      payment_type: paymentType,
      status: status,
      transaction_id: transactionId,
      userID: userId,
    });
    for (let i = 0; i < selectedMedicines.length; i++) {
      const product = selectedMedicines[i];
      const updatedStockQuantity = product.stock_quantity - product.quantity;
      await axios.put(`${apiurl}/api/inventorystock/${product.inventory_id}`, {
        stock_quantity: updatedStockQuantity
      });
    }
    // Show success message
   alert("Successfully added billing!");
   handlePrintBill();
   window.location.reload();
    // Print the bill
         } catch (error) {
    console.error('Error adding billing record:', error);
    // Show error message if adding billing record fails
    alert('Error adding billing record. Please try again.');
  }
};
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const getOptionLabel = (option) => {
    const parts = [];
    if (option.generic_name) parts.push(option.generic_name);
    if (option.brand_name) parts.push(`(${option.brand_name})`);
    if (option.salts_ingredients) parts.push(`(${option.salts_ingredients})`);
    if (option.barcode) parts.push(`(${option.barcode})`);
    return parts.join(' ') || 'Search Drug';
  };
  // const handleInventorySearchChange = async event => {
  //   setInventorySearchTerm(event.target.value);
  //   try {
  //     const response = await axios.get(`${apiurl}api/inventory/${event.target.value}`);
  //     setInventory(response.data);
  //   } catch (error) {
  //     console.error('Error searching inventory:', error);
  //   }
  // };

  const handleChangeQuantity = (index, quantity) => {
    // Update the quantity of the selected medicine at the specified index
    const updatedMedicines = selectedMedicines.map((medicine, i) =>
      i === index ? { ...medicine, quantity: quantity } : medicine
    );
    setSelectedMedicines(updatedMedicines);
  };
  const addBlankMedicine = () => {
    setSelectedMedicines(prev => [
      ...prev,
      {
        product_name: '',
        selling_price: '',
        stock_quantity: '',
        quantity: '',
        expiration_date: '',
        location: '',
        batch_number: ''
      }
    ]);
  };

  useEffect(() => {
    if (selectedMedicines.length === 0) {
      addBlankMedicine();
    }
  }, [selectedMedicines]);
  const handlePrintBill = () => {
    const userId = Cookies.get('userID'); // Get the userID from the cookie
    const Prescriptionids = PrescriptionId;
    const date = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const totalAmount = selectedMedicines.reduce((total, medicine) => total + (parseFloat((medicine.selling_price/medicine.units_per_package)) * medicine.quantity), 0).toFixed(2);
    const discountAmount = (totalAmount * (discount / 100)).toFixed(2); // Calculate discount amount
    const netBill = (totalAmount * (1 - (discount / 100))).toFixed(2); // Calculate net bill
  
    const centerText = (text, width) => {
      const padding = Math.max(0, (width - text.length) / 2);
      return ' '.repeat(padding) + text;
    };
    
    const receiptWidth = 100; // Adjust the width according to your receipt printer
    
    let receipt = "";
    receipt += centerText("VaidhyaHC Pharmacy", receiptWidth) + "\n";
    receipt += centerText("4-65/1, OPP IOC PETROL BUNK,", receiptWidth) + "\n";
    receipt += centerText("DOMADUGU V, GUMMADIDALA M, 500043,", receiptWidth) + "\n";
    receipt += centerText("SANGA REDDY DIST, Telangana, India,", receiptWidth) + "\n";
    receipt += centerText("Tel:7893270879", receiptWidth) + "\n";
    receipt += centerText("Gstin:36AAYFV3486H1ZA", receiptWidth) + "\n\n";
    receipt += `Date: ${date}\n\n`;
    receipt += `PrescriptionID: ${Prescriptionids}\n\n`; 
    receipt += `Bill Created By: ${userId}\n\n`;
    // Add each medicine detail to the receipt
    selectedMedicines.forEach((medicine, index) => {
      const total = (parseFloat((medicine.selling_price/medicine.units_per_package)) * medicine.quantity).toFixed(2);
      receipt += `${index + 1}. ${medicine.brand_name || medicine.generic_name || medicine.salts_ingredients }\n`;
      receipt += `   Price: ₹${(medicine.selling_price/medicine.units_per_package) }\n`;
      receipt += `   Quantity: ${medicine.quantity}\n`;
      receipt += `   Total: ₹${total}\n\n`;
    });
  
    // Add the total amount, discount amount, and net bill to the receipt
    receipt += `Total Amount: ₹${totalAmount}\n`;
    receipt += `Discount Amount: ₹${discountAmount}\n`;
    receipt += `Net Bill: ₹${netBill}\n`;
  
    // Create a hidden iframe to hold the receipt content
    const printIframe = document.createElement('iframe');
    printIframe.style.position = 'absolute';
    printIframe.style.width = '0';
    printIframe.style.height = '0';
    printIframe.style.border = 'none';
    document.body.appendChild(printIframe);
  
    const printDocument = printIframe.contentWindow.document;
    printDocument.open();
    printDocument.write('<html><head><title>Print Receipt</title></head><body>');
    printDocument.write('<pre>' + receipt + '</pre>');
    printDocument.write('</body></html>');
    printDocument.close();
  
    printIframe.contentWindow.focus();
    printIframe.contentWindow.print();
  
    // Remove the iframe after printing
    setTimeout(() => {
     document.body.removeChild(printIframe);
    }, 3000);
  };
     
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  

     const handleAddMedicine = () => {
    setSelectedMedicines([...selectedMedicines, { product_name: '', selling_price: 0, quantity: 0 }]);
    scrollToTop();
  };
  const scrollToTop = () => {
    // Scroll to the top of the table
    if (tableRef.current) {
      const firstRow = tableRef.current.querySelector('tbody tr:last-child');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };
  useEffect(() => {
    // Scroll to the top of the table when selectedMedicines changes
    scrollToTop();
  }, [selectedMedicines]);
  const handleRemoveMedicine = (index) => {
    const updatedMedicines = [...selectedMedicines];
    updatedMedicines.splice(index, 1);
    setSelectedMedicines(updatedMedicines);
  };
  

  const handleProductNameChange = (newValue, index) => {
    if (newValue) {
      // Find the selected medicine from the inventory
      const selectedMedicine = inventory.find(medicine =>
        medicine.generic_name === newValue.generic_name && medicine.brand_name === newValue.brand_name && medicine.salts_ingredients === newValue.salts_ingredients
      );
      // Update the selected medicine in the state
      setSelectedMedicines(prevState => {
        const updatedMedicines = [...prevState];
        updatedMedicines[index] = {
          ...selectedMedicine,
          quantity: updatedMedicines[index] ? updatedMedicines[index].quantity : 0
        };
        return updatedMedicines;
      });
    }
  };
 
useEffect(() => {
  let timer;

  if (showSuccessMessage) {
    timer = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  }
  if (showDeleteSuccessMessage) {
    timer = setTimeout(() => {
      setshowDeleteSuccessMessage(false);
    }, 3000);
  }
  
  return () => clearTimeout(timer);
}, [showSuccessMessage, showDeleteSuccessMessage]);
  return (    
    <div> 
       {userRole === 'P' && (<PharmaHeader />)}
       {userRole === 'PA' && (<PharmaAssistent />)} 
                         
  <div>
  <Grid >
            <Grid >          
      <Paper square elevation={2} style={{ backgroundColor: '#18B7BE', padding: '10px', color:'white' }}>
        <Typography variant="h4" component="h1" align="center">Vaidhya Pharmacy - OTC Sale/Billing</Typography>
      </Paper>     
</Grid>
</Grid>
  {/* Table displaying selected medicines */}
  <div style={{ maxHeight: '300px', overflow: 'auto' }} ref={tableRef}>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
            <TableCell style={{ width: '5%' }}>Product Name</TableCell>
            <TableCell style={{ width: '5%' }}>Unit Price</TableCell>
            <TableCell style={{ width: '5%' }}>Stock Quantity</TableCell>
            <TableCell style={{ width: '5%' }}>Quantity of Sale</TableCell>
            <TableCell style={{ width: '5%' }}>Expiration Date</TableCell>
            <TableCell style={{ width: '5%' }}>Location</TableCell>
            <TableCell style={{ width: '5%' }}>Batch Number</TableCell>
            <TableCell style={{ width: '5%', paddingRight: '2%' }}>Total Price</TableCell>
            <TableCell style={{ width: '5%', paddingLeft: '2%' }}>Actions</TableCell>

      </TableRow>
    </TableHead>
    <TableBody>
      {selectedMedicines.map((medicine, index) => (
        <TableRow key={index}>
          <TableCell>
          <Autocomplete
      value={medicine || null}
      onChange={(_event, newValue) => handleProductNameChange(newValue, index)}
      options={inventory}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} label="Search Drug" variant="outlined" style={{ width: '100%' }} />
      )}
    />
    </TableCell>
          <TableCell>{medicine.selling_price/medicine.units_per_package}</TableCell>
          <TableCell>{medicine.stock_quantity}</TableCell>
          <TableCell>

            <TextField
              type="number"
              value={medicine.quantity}
              onChange={(e) => handleChangeQuantity(index, e.target.value)}
            />
          </TableCell>
          <TableCell>{medicine.expiration_date ? new Date(medicine.expiration_date).toLocaleDateString() : ''}</TableCell>
          <TableCell>{medicine.location}</TableCell>
          <TableCell>{medicine.batch_number}</TableCell>
          <TableCell>{((medicine.selling_price/medicine.units_per_package) * medicine.quantity).toFixed(2)}</TableCell>
          <TableCell>
          <Button onClick={() => handleAddMedicine(index)}>Add</Button>
            <Button onClick={() => handleRemoveMedicine(index)}>Remove</Button>
            
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  </TableContainer>
  </div>
  <Grid container alignItems="center" style={{ position: 'fixed', bottom: '0', width: '100%', backgroundColor: '#18B7BE', borderTop: '1px solid #ccc', padding: '0.5%' }}>
      {/* Left side */}
      <Grid item style={{ padding: '0.5%', marginLeft: '1%' }}>
        <TextField
          label="TD %"
          type="number"
          value={discount}
          InputProps={{ inputProps: { step: "1", min: "0", max: "30" }, style: { borderRadius: '5px' } }}
          onChange={handleDiscountChange}
          InputLabelProps={{
            style: { color: '#000000' }, // Label color
          }}
          variant="outlined"
          style={{ width: '150%', backgroundColor: '#FCFBFC', borderRadius: '5px' }}
        />
      </Grid>
      <Grid item style={{ padding: '0.5%', marginLeft: '3%' }}>
        <TextField
          label="Total Amount"
          value={selectedMedicines.reduce((total, medicine) => total + ((medicine.selling_price/medicine.units_per_package) * medicine.quantity), 0).toFixed(2)}
          disabled
          InputProps={{
            startAdornment: <Typography variant="h6">₹</Typography>,
            }}
          InputLabelProps={{
            style: { color: '#000000' }, // Label color
          }}
          variant="outlined"
          style={{ width: '80%', backgroundColor: '#FCFBFC', borderRadius: '5px' }}
        />
      </Grid>
      <Grid item style={{ padding: '0.5%', marginRight: '0.5%' }}>
        <TextField
          label="TD Amount"
          value={(selectedMedicines.reduce((total, medicine) => total + ((medicine.selling_price/medicine.units_per_package) * medicine.quantity), 0) * discount / 100).toFixed(2)}
          disabled
          InputProps={{
            startAdornment: <Typography variant="h6">₹</Typography>,
          }}
          InputLabelProps={{
            style: { color: '#000000' }, // Label color
          }}
          variant="outlined"
          style={{ width: '80%', backgroundColor: '#FCFBFC', borderRadius: '5px' }}
        />
      </Grid>
      <Grid item style={{ padding: '0.5%', marginRight: '0.5%' }}>
        <TextField
          label="Net Bill"
          value={(selectedMedicines.reduce((total, medicine) => total + ((medicine.selling_price/medicine.units_per_package) * medicine.quantity), 0) * (1 - (discount / 100))).toFixed(2)}
          disabled
          InputProps={{
            startAdornment: <Typography variant="h6">₹</Typography>,
      }}
      InputLabelProps={{
        style: { color: '#000000' }, // Label color
      }}
          variant="outlined"
          style={{ width: '80%', backgroundColor: '#FCFBFC', borderRadius: '5px' }}
        />
      </Grid>

      {/* Right side */}
      <Grid item style={{ marginTop: '0.5%', marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => handlePaymentTypeSelect('CASH')} style={{ backgroundColor: '#178CA4', color: 'white', marginRight: '5px' }}>
            Cash
          </Button>
          <Dialog open={showCashPrompt} onClose={() => setShowCashPrompt(false)}>
        <DialogTitle>Enter Cash Amount</DialogTitle>
        <DialogContent>
          <TextField
            label="Cash Amount"
            value={cashInput}
            onChange={(e) => setCashInput(e.target.value)}
            fullWidth
            type="number"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCashPrompt(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCashInputConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal dialog for showing messages */}
      <Dialog open={showMessage} onClose={() => setShowMessage(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMessage(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={() => handlePaymentTypeSelect('CARD')}
        style={{ backgroundColor: '#178CA4', color: 'white', marginRight: '5px' }}>
        Payment Terminal
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter Transaction ID</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Transaction ID"
            type="text"
            fullWidth
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
        </div>
      </Grid>
    </Grid>
            
         <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <h2 id="modal-title">Total Bill Amount: {totalAmount}</h2>
          <h3>List of Medicines:</h3>
          <ul>
            {selectedMedicines.map((medicine, index) => (
              <li key={index}>
                {medicine.product_name} - {medicine.Price_per_unit} - Quantity: {medicine.quantity}
              </li>
            ))}
          </ul>
          <Button onClick={handleCloseModal}>Close</Button>
        </div>
        </Modal>
        </div>
        </div>
  );         
}
export default Counterbill;
