import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Link, Route, Routes , useNavigate, useLocation } from 'react-router-dom';
import vaidhyalogo from '../images/V2.jpg';
import Products from '../Product/product'; 
import InventoryChart from '../audits/Inventory';
import { logout } from '../Homepage/sessionService';

function PharmaAssistent() {
  
     const navigate = useNavigate();
    const location = useLocation();
    const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

    const handleLogout = () => {
        // Open the logout confirmation dialog
        setLogoutConfirmationOpen(true);
    };

    const handleConfirmLogout = async () => {
       
        // Call the logout function to perform logout and redirect
        await logout(navigate);
    };

    const handleCancelLogout = () => {
        // Close the logout confirmation dialog
        setLogoutConfirmationOpen(false);
    };

    const handleLogoClick = () => {
        navigate('billingpa');
    };
    
    
  return (
        <div>
        <AppBar position="static" style={{ height: '4rem', backgroundColor: '#18B7BE' }}>
       <Toolbar>
  <img src={vaidhyalogo} alt="Vaidhya Logo" width="100"  style={{ cursor: 'pointer',  maxWidth: '3%', height: 'auto', marginLeft: '1%' }} />
  <Typography variant="h8" component="div" sx={{ flexGrow: 1, color: 'white' }}>
     Pharmacy Management System
  </Typography>

                    <Button color="inherit" component={Link} to="/billingpa" style={{ color: location.pathname === '/billingpa' ? '#072A40' : 'white' }}>
                        Billing
                    </Button>
                    <Button color="inherit" component={Link} to="/inventorypa" style={{ color: location.pathname === '/inventorypa' ? '#072A40' : 'white' }}>
                        Inventory
                    </Button>
                    <Button color="inherit" component={Link} to="/orderpa" style={{ color: location.pathname === '/orderpa' ? '#072A40' : 'white' }}>
                        Order
                    </Button>
                                       
                    <Button color="inherit" onClick={handleLogout} style={{ color: location.pathname === '/logout' ? '#072A40' : 'white' }}>
                        Logout
                    </Button>
          </Toolbar>
        </AppBar>
      

      <Routes>
        <Route path="/chart" element={<InventoryChart />} />
        <Route path="/Inventory" element={<Products />} />
      </Routes>
      <Dialog open={logoutConfirmationOpen} onClose={handleCancelLogout}>
                <DialogTitle>Confirm Logout</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelLogout} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmLogout} color="primary">
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
      </div>
      
  );
};

export default PharmaAssistent;